import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import BaseLayout from 'components/baseLayout';
import Pagination from '@material-ui/lab/Pagination';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles } from '@material-ui/core';
import { PromoList } from './list/list';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import {
  promoIsLoadingSelector,
  promoListCountSelector,
  promoListSelector,
  resetPromoList,
} from 'store/reducers/promo';
import { createPromo, getPromoList } from 'store/reducers/promo/actions';
import { Header } from 'components/shared/header/Header';
import { generatePath, useHistory, useLocation } from 'react-router';
import { Routes } from 'constants/Routes';
import CreateButton from 'components/shared/buttons/CreateButton';
import { PromoEditModal } from './edit/edit-modal';
import { Promo } from 'store/reducers/promo/types';
import Loader from 'components/shared/loader';

const ITEMS_PER_PAGE = 10;

export const PromoListPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { search } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const pageQuery = query.get('page');
  const page = pageQuery ? parseInt(pageQuery) : 1;

  const [isCreateModalVisible, setCreateModalVisibility] = useState(false);

  const promo = useSelector(promoListSelector);
  const count = useSelector(promoListCountSelector);
  const loading = useSelector(promoIsLoadingSelector);

  const pageCount = Math.ceil(count / ITEMS_PER_PAGE);

  const handlePageChange = useCallback(
    (_event: ChangeEvent<unknown>, page: number) => {
      const search = new URLSearchParams({ page: `${page}` }).toString();
      history.push(`${Routes.promos}?${search}`);
    },
    [history],
  );

  const handleItemClick = (id: string) => {
    history.push(generatePath(Routes.promo, { id }));
  };

  const openCreateModal = () => setCreateModalVisibility(true);

  const closeCreateModal = () => setCreateModalVisibility(false);

  const reload = useCallback(() => {
    const offset = (page - 1) * ITEMS_PER_PAGE;
    const limit = ITEMS_PER_PAGE;

    dispatch(getPromoList({ offset, limit }));
  }, [dispatch, page]);

  const handleCreate = async (data: Partial<Promo>) => {
    const { photo, photoId, ...promoWithoutPhoto } = data;
    const newPhotoId = photo?.id ?? null;

    await dispatch(createPromo({ photoId: newPhotoId, ...promoWithoutPhoto }));
    closeCreateModal();
  };

  useEffect(() => reload(), [reload]);

  useEffect(() => () => void dispatch(resetPromoList()), [dispatch]);

  useEffect(() => {
    if (!pageCount || (page >= 1 && page <= pageCount)) return;
    handlePageChange({} as ChangeEvent<unknown>, pageCount);
  }, [page, pageCount, handlePageChange]);

  return (
    <BaseLayout>
      <div className={classes.root}>
        <Header title="Промо">
          <CreateButton onClick={openCreateModal} />
        </Header>
        <Divider className={classes.divider} />
        <div className={classes.content}>
          {loading && <Loader show />}
          {!loading && <PromoList items={promo} onItemClick={handleItemClick} />}
        </div>
        <Divider className={classes.divider} />
        <Pagination
          className={classes.pagination}
          page={page}
          count={pageCount}
          shape="rounded"
          onChange={handlePageChange}
        />
      </div>
      <PromoEditModal open={isCreateModalVisible} onClose={closeCreateModal} onSubmit={handleCreate} />
    </BaseLayout>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
    header: {
      marginBottom: theme.spacing(1),
    },
    content: {
      flex: 1,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    divider: {
      backgroundColor: '#f2f2f2',
    },
    pagination: {
      marginLeft: 'auto',
      padding: theme.spacing(1),
    },
  }),
);
